<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card>
            <!-- input search -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Recherche
                  </label>
                  <b-form-input
                    v-model="searchTerm"
                    x
                    placeholder="Recherche"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
              <!-- primary -->
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Exporter"
                variant="primary"
                class="ml-2 btn_export"
              >
                <b-dropdown-item @click="exportCSV">
                  CSV
                </b-dropdown-item>
                <b-dropdown-item @click="exportExcel">
                  MS-Excel
                </b-dropdown-item>
              </b-dropdown>

              <!-- button -->
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.user-modal-prevent-closing
                variant="outline-primary"
                class="btn_export ml-2"
              >
                <feather-icon icon="PlusCircleIcon"/>
                Ajouter un utilisateur
              </b-button>

              <!-- modal -->
              <b-modal
                id="user-modal-prevent-closing"
                ref="user-modal"
                title="Nouveau user"
                ok-title="Enregistrer"
                cancel-variant="outline-secondary"
                cancel-title="Annuler"
                size="lg"
                @hidden="resetModal"
                @ok="handleOk"
              >
                <form
                  ref="form"
                  @submit.stop.prevent="handleSubmit"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        :state="user.prenomState"
                        label="Prénom"
                        label-for="prenom-input"
                        invalid-feedback="Le nom est requis"
                      >

                        <b-form-input
                          id="prenom-input"
                          v-model="user.first_name"
                          :state="user.prenomState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="user.nomState"
                        label="Nom"
                        label-for="nom-input"
                        invalid-feedback="Le nom est requis"
                      >

                        <b-form-input
                          id="nom-input"
                          v-model="user.last_name"
                          :state="user.nomState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="user.telephoneState"
                        label="Téléphone"
                        label-for="phone-input"
                        invalid-feedback="Le téléphone est requis"
                      >

                        <b-form-input
                          id="phone-input"
                          v-model="user.telephone"
                          :state="user.telephoneState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Email"
                        label-for="email-input"
                        invalid-feedback="L'email est requis"
                      >

                        <b-form-input
                          id="email-input"
                          v-model="user.email"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!updateUser"
                      md="6"
                    >
                      <b-form-group
                        :state="user.usernameState"
                        label="Login de connexion"
                        label-for="username-input"
                        invalid-feedback="Le login est requis"
                      >

                        <b-form-input
                          id="username-input"
                          v-model="user.username"
                          :state="user.usernameState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!updateUser"
                      md="6"
                    >
                      <b-form-group
                        :state="user.passwordState"
                        label=" Mot de passe"
                        label-for="mot-de-passe-input"
                        invalid-feedback="Le mot de passe est requis"
                      >

                        <b-form-input
                          id="mot-de-passe-input"
                          v-model="user.password"
                          :state="user.passwordState"
                          type="password"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="user.responsableState"
                        label="Responsable"
                        label-for="type-input"
                        invalid-feedback="Le responsable est requis"
                      >

                        <v-select
                          v-model="user.responsable"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="full_name"
                          :options="responsables"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="user.profileState"
                        label="Profil"
                        label-for="profile-input"
                        invalid-feedback="Le profil est requis"
                      >
                        <v-select
                          id="profile-input"
                          v-model="user.profile"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="nom"
                          :options="profils"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="user.canalState"
                        label="Canal de distribution"
                        label-for="canal-input"
                        invalid-feedback="Le canal est requis"
                      >

                        <v-select
                          id="canal-input"
                          v-model="user.canal"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="nom"
                          :options="canaux"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </form>
              </b-modal>
            </div>
            <vue-good-table
              id="list"
              ref="myTable"
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Status -->
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="ml-1"
                      variant="outline-primary"
                      @click="handleUpdate(props.row)"
                    >
                      Modifier
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="ml-1"
                      variant="outline-warning"
                      @click="confirmAction(props.row)"
                    >
                      Reset password
                    </b-button>
                  </b-button-group>

                </span>
                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Affichage de 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> sur {{ props.total }} élements </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BButton, BButtonGroup,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect, BModal,
  BPagination,
  BRow, VBModal,
} from 'bootstrap-vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import vSelect from 'vue-select'
import { API_URL } from '@/helpers/global-scops'
import showToast from '@/helpers/methodes'

export default {
  name: 'MainVue',
  components: {
    BButtonGroup,
    BModal,
    BButton,
    BBadge,
    VueGoodTable,
    BCard,
    BCol,
    BRow,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
    htmlToPaper,
    'b-modal': VBModal,
  },
  data() {
    return {
      responsables: [],
      profils: [],
      canaux: [],
      updateUser: false,
      user: {
        nom: '',
        prenom: '',
        telephone: '',
        email: '',
        canal: '',
        responsable: '',
        profile: '',
        username: '',
        password: '',
        nomState: null,
        prenomState: null,
        responsableState: null,
        profilState: null,
        telephoneState: null,
        canalState: null,
        emailState: null,
        usernameState: null,
        passwordState: null,
      },
      name: '',
      nameState: null,
      submittedNames: [],
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Prenom',
          field: 'first_name',
        },
        {
          label: 'Nom',
          field: 'last_name',
        },
        {
          label: 'Nom d\'utilisateur',
          field: 'username',
        },
        {
          label: 'Profil',
          field: 'profile',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.resetData()
  },
  methods: {
    resetData() {
      this.getUsers()
      this.getProfils()
      this.getCanaux()
      this.getResponsables()
    },
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(','))
        .join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    checkFormValidity() {
      this.user.profileState = !!this.user.profile
      this.user.nomState = this.user.last_name.length > 0
      this.user.prenomState = this.user.first_name.length > 0
      this.user.telephoneState = this.user.telephone.length > 0
      if (!this.updateUser) {
        this.user.usernameState = this.user.username.length > 0
        this.user.passwordState = this.user.password.length > 0
      } else {
        this.user.usernameState = true
        this.user.passwordState = true
      }
      return this.user.nomState
        && this.user.prenomState
        && this.user.telephoneState
        && this.user.profileState
        && this.user.usernameState
        && this.user.passwordState
    },
    resetModal() {
      this.user.uuid = null
      this.user.last_name = ''
      this.user.nomState = null
      this.user.canal = ''
      this.user.canalState = null
      this.user.first_name = ''
      this.user.prenomState = null
      this.user.telephone = ''
      this.user.telephoneState = null
      this.user.profile = ''
      this.user.profileState = null
      this.user.email = ''
      this.user.username = ''
      this.user.password = ''
      this.user.usernameState = null
      this.user.passwordState = null
    },
    handleUpdate(item) {
      console.log(item)
      this.updateUser = true
      this.user.uuid = item.uuid
      this.user.last_name = item.last_name
      this.user.canal = item.canal_r
      this.user.first_name = item.first_name
      this.user.telephone = item.telephone
      this.user.profile = item.profile_r
      this.user.email = item.email
      this.user.username = item.username
      this.user.responsable = item.responsable_r
      this.$refs['user-modal'].toggle('#toggle-btn')
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.saveUsers()
    },
    getUsers() {
      this.$http.get(`${API_URL}users/`)
        .then(response => {
          this.rows = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    saveUsers() {
      if (!this.user.uuid && this.user.uuid == null) {
        this.$http.post(`${API_URL}users/`, this.user)
          .then(() => {
            showToast(this, 'primary', 'Ajout', 'Element ajouté')
            this.resetData()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs['user-modal'].toggle('#toggle-btn')
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$http.put(`${API_URL}users/${this.user.uuid}/`, this.user)
          .then(response => {
            console.log(response.data)
            showToast(this, 'primary', 'Ajout', 'Element modifié')
            this.resetData()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs['user-modal'].toggle('#toggle-btn')
            })
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    handleDelete(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
        this.$http.delete(`${API_URL}users/${id}/`)
          .then(response => {
            console.log(response.data)
            showToast(this, 'danger', 'Suppression', 'Element supprimé')

            this.getUsers()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        // Annuler la suppression ici
      }
    },
    getProfils() {
      this.$http.get(`${API_URL}profiles/`)
        .then(response => {
          this.profils = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getCanaux() {
      this.$http.get(`${API_URL}canals/`)
        .then(response => {
          this.canaux = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResponsables() {
      this.$http.get(`${API_URL}responsables/`)
        .then(response => {
          this.responsables = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    confirmAction(user) {
      if (window.confirm('Êtes-vous sûr de vouloir réinitialiser le mot de passe ?')) {
        if (user.uuid) {
          this.$http.post(`${API_URL}password-reset/`, { uuid: user.uuid })
            .then(() => {
              showToast(this, 'primary', 'réinitialisation', 'Mot de passe réinitialisé')
              // this.resetData()
            })
            .catch(error => {
              console.log(error)
            })
        }
      } else {
        // Le code ici sera exécuté si l'utilisateur clique sur "Annuler".
        // Ici, vous pouvez gérer le cas où l'utilisateur annule l'action.
      }
    },
  },
}
</script>

<style scoped>

</style>
